import { mixins, theme } from '../../../styles'
import { PictureElementList } from '../../atoms/PictureElementList'
import { ArticleHeaderX } from '../ArticleHeaderX'
import { BlockData } from '../../templates/Block'

export function ArticleHeaderImage({
  title,
  format,
  credit,
  sponsor,
  pictures,
  link,
  target,
  hasMultiLive,
}: BlockData<'article-header-image'>): JSX.Element {
  const WrapperComponent = link ? 'a' : 'div'
  const wrapperProps = link ? { href: link, target } : {}

  return (
    <>
      <ArticleHeaderX
        format={format}
        credit={credit}
        sponsor={sponsor}
        title={title}
        hasMultiLive={hasMultiLive}
      >
        <figure className="ArticleHeaderImage__Figure">
          <WrapperComponent className="ArticleHeaderImage__Image" {...wrapperProps}>
            <PictureElementList
              elementList={pictures?.elementList}
              alt={pictures?.alt}
              importance="high"
              fetchPriority="high"
              withPreload
            />
          </WrapperComponent>
          {pictures?.caption || pictures?.copyright ? (
            <figcaption className="ArticleHeaderImage__Caption">
              {pictures?.caption}
              {pictures?.copyright ? (
                <>
                  {pictures?.caption ? ' - ' : ''}
                  <span className="ArticleHeaderImage__Source"> Source : </span>
                  {pictures.copyright}
                </>
              ) : null}
            </figcaption>
          ) : null}
        </figure>
      </ArticleHeaderX>

      <style jsx>{`
        .ArticleHeaderImage__Figure {
          margin-bottom: 10px;
          width: 100%;
        }

        .ArticleHeaderImage__Image {
          position: relative;
          padding-top: 56.25%;
          width: 100%;
          border-radius: 4px;
          overflow: hidden;
          z-index: 2;
          display: block;
          cursor: ${link ? 'cursor' : ''};
        }

        .ArticleHeaderImage__Image :global(amp-img),
        .ArticleHeaderImage__Image :global(img),
        .ArticleHeaderImage__Image :global(.PictureElementList_Picture_Error) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .ArticleHeaderImage__Caption {
          padding: 0 30px;
          margin-top: 10px;
          color: ${theme.cssVars.midBlue};
          font-size: 15px;
          line-height: 145%;
        }

        .ArticleHeaderImage__Caption p {
          margin: 0;
        }

        .ArticleHeaderImage__Source {
          font-size: 13px;
          color: ${theme.cssVars.white};
        }
      `}</style>
    </>
  )
}
